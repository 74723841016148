<template>
    <v-dialog v-model="dialogStyles" max-width="1000px"
        @click:outside="closeDialog"
    >
        <v-card style="position: relative;">
            <v-card-title
                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                class="d-flex align-center"
            >
                <span class="light--text">{{ `Personalizar estilo da linha`}}</span>
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on"
                            class="pa-0"
                            @click="closeDialog"
                            min-width="48px"
                            text dark
                        >
                            <v-icon color="light">mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row no-gutters class="mt-2">
                        <template>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <div class="text-subtitle-1 dark--text">
                                    Estilos:
                                </div>

                                <template v-if="rowStyles.length">
                                    <v-chip v-for="style in rowStyles" :key="style"
                                        close
                                        @click:close="removeStyle(style)"
                                        color="transparent"
                                        class="mt-2"
                                    >
                                        {{ style }}
                                    </v-chip>
                                </template>

                                <div v-else class="mt-2">
                                    Nenhum estilo selecionado
                                </div>

                            </v-col>
                        </template>
                    </v-row>

                    <v-row no-gutters class="mt-4">
                        <v-col>
                            <span class="text-subtitle-1 dark--text">Preenchimentos:</span>
                        </v-col>

                        <v-col>
                            <span class="text-subtitle-1 dark--text">Fontes:</span>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col class="d-flex">
                            <div v-for="background in backgrounds" :key="background.class" class="d-flex flex-column">
                                <v-chip v-for="n in 4" :key="n"
                                    label
                                    class="mt-2 mr-2"
                                    style="width: 24px; height: 24px; cursor: pointer;"
                                    :class="`${background.class}-${n}`"
                                    :style="rowStyles.includes(`${background.class}-${n}`) ? 'box-shadow: 0px 0px 0px 1.4px #666;' : ''"
                                    @click="setBackground(`${background.class}-${n}`)"
                                >
                                </v-chip>
                            </div>
                        </v-col>

                        <v-col class="d-flex flex-column">
                            <div class="d-flex flex-column mt-2">
                                <span>Formatação: </span>

                                <div class="d-flex mt-2">
                                    <v-btn text class="pa-0 mr-2"
                                        v-for="fontFormat in fontFormats" :key="fontFormat.class"
                                        @click="setFontFormat(fontFormat.class)"
                                        :class="{'primary': rowStyles.includes(fontFormat.class)}"
                                    >
                                        <v-icon>{{ fontFormat.icon }}</v-icon>
                                    </v-btn>
                                </div>
                            </div>

                            <div class="d-flex flex-column mt-4">
                                <span class="mb-2">Tamanho: </span>

                                <div class="d-flex">
                                    <v-btn text class="pa-0 text-lowercase d-flex"
                                        v-for="fontSize in fontSizes" :key="fontSize.class"
                                        @click="setFontSize(fontSize.class)"
                                        :class="{'primary': rowStyles.includes(fontSize.class)}"
                                    >
                                        <span>{{ fontSize.name }}</span>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-4">
                        <v-col cols="2" class="d-flex flex-column">
                            <span class="text-subtitle-1 dark--text">Bordas:</span>
                            <span class="mt-2">Formatação: </span>
                        </v-col>
                        <v-col class="d-flex flex-column">
                            <span class="mt-9">Tamanho: </span>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-2">
                        <v-col cols="2">
                            <v-btn icon v-for="borderFormat in borderFormats" :key="borderFormat.id" 
                                @click="setBorderFormat(borderFormat)"
                                class="mr-1"
                                :class="{'primary': setSelectedBorderFormat(borderFormat)}"
                            >
                                <v-icon :color="setSelectedBorderFormat(borderFormat) ? 'white' : 'black'">
                                    {{ borderFormat.icon }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col>
                            <div class="d-flex">
                                <v-btn text class="pa-0 text-lowercase d-flex"
                                    v-for="borderSize in borderSizes" :key="borderSize.class"
                                    @click="setBorderSize(borderSize)"
                                    :class="{'primary': selectedBorderSize === borderSize.size}"
                                    :disabled="disableBorderSizes"
                                >
                                    <span>{{ borderSize.name }}</span>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-4">
                        <v-col>
                            <div>
                                Visualização:
                            </div>
                            <div style="width: 100%; height: 100%; border: 1px solid #999999; color: #222222;"
                                class="d-flex align-center justify-center mt-2"
                                :class="rowStyles.join(' ')"
                            >
                                Lorem ipsum dolor sit amet
                            </div>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-16">
                        <v-btn
                            color="primary"
                            text
                            @click="rowStyles = []"
                            :disabled="rowStyles.length ? false : true"
                        >
                            Remover estilos
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="$emit('closeDialogStyles')"
                            class="mr-4"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="save()"
                            :disabled="disabled"
                            :loading="saving"
                        >
                            Salvar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ZnapDialog',

    props: {
        editedRowStyles: { type: Object, required: true },
        dialogStyles: { type: Boolean, required: true }
    },

    data() {
        return {
            saving: false,
            rowStyles: [],
            backgrounds: [
                { class: 'bordeaux', name: 'vinho' },
                { class: 'red', name: 'vermelho' },
                { class: 'gold', name: 'ouro' },
                { class: 'yellow', name: 'amarelo' },
                { class: 'lime', name: 'verde limão' },
                { class: 'green', name: 'verde' },
                { class: 'blue', name: 'azul' },
                { class: 'royal', name: 'azul royal' },
                { class: 'navy', name: 'azul marinho' },
                { class: 'purple', name: 'roxo' },
                { class: 'silver', name: 'prata' },
            ],
            fontSizes: [
                { class: 'font-smaller', name: '10pt' },
                { class: 'font-small', name: '12pt' },
                { class: 'font-normal', name: '14pt' },
                { class: 'font-big', name: '16pt' },
                { class: 'font-bigger', name: '18pt' },
            ],
            fontFormats: [
                { class: 'font-bold', name: 'Negrito', icon: 'mdi-format-bold' },
                { class: 'font-italic', name: 'Itálico', icon: 'mdi-format-italic' },
                { class: 'font-underline', name: 'Sublinhado', icon: 'mdi-format-underline' },
            ],

            initialSelectedBorderSize: null,
            selectedBorderSize: null,
            borderSizes: [
                { size: 1, name: '1px' },
                { size: 2, name: '2px' },
                { size: 3, name: '3px' },
                { size: 4, name: '4px' },
            ],
            borderFormats: [
                { id: 1, icon: 'mdi-border-all', class: 'border-all' },
                { id: 2, icon: 'mdi-border-bottom-variant', class: 'border-bottom' },
                { id: 3, icon: 'mdi-border-top-variant', class: 'border-top' },
            ],

            disabled: true,
        }
    },

    computed: {
        disableBorderSizes() {
            return !this.rowStyles.join(' ').includes('border')
        },
    },

    watch: {
        rowStyles(val) {
            if (val.join(' ') === this.editedRowStyles.css) {
                this.disabled = true
            } else {
                this.disabled = false
            }
        },

        selectedBorderSize(val) {
            if (val === this.initialSelectedBorderSize) {
                this.disabled = true
            } else {
                this.disabled = false
            }
        }
    },

    created() {
        if (this.editedRowStyles.css === "" || this.editedRowStyles.css === null) {
            this.rowStyles = []
        } else {
            this.rowStyles = this.editedRowStyles.css.split(' ')
            let index = this.rowStyles.findIndex(r => r.includes('border'))
            if (index > -1) {
                this.selectedBorderSize = parseInt(this.rowStyles[index].split('-')[2], 10)
            }
        }

        this.initialSelectedBorderSize = this.selectedBorderSize
    },

    methods: {
        async save() {
            this.saving = true
            let css = this.rowStyles.join(' ')
            let id = this.editedRowStyles.id

			let payload = { items: [
				{
					id_account_group: id,
					css
				}
			]}

            try {
                const res = await this.$http.put('https://api.account.znaptech.com/account-group/edit-css', { ...payload })
                if (res) {
                    this.saving = false
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }

            this.$emit('setEditedRowStyle', css)
            this.closeDialog()
        },

        closeDialog() {
            this.$emit('closeDialogStyles')
        },

        removeStyle(style) {
            if (style.includes('border-all')) {
                this.selectedBorderSize = null
            }

            let index = this.rowStyles.findIndex(s => s === style)
            this.rowStyles.splice(index, 1)
        },

        setBackground(background) {
            let isBg = null
            this.rowStyles.forEach(style => {
                let styleClass = style.split('-')[0]
                let bgIndex = this.backgrounds.find(bg => bg.class === styleClass)
                if (bgIndex) isBg = bgIndex
            })

            let found = this.rowStyles.find(style => style === background)

            if (isBg) {
                if (found) {
                    this.removeStyle(background)
                } else {
                    this.rowStyles.splice(0, 1, background)
                }
            } else {
                this.rowStyles.splice(0, 0, background)
            }
        },

        setFontSize(fontSize) {
            let isFontSize = null
            this.rowStyles.forEach((style, index) => {
                let fsIndex = this.fontSizes.find(fs => fs.class === style)
                if (fsIndex) isFontSize = index
            })

            let found = this.rowStyles.find(style => style === fontSize)

            if (isFontSize !== null) {
                if (found) {
                    this.removeStyle(fontSize)
                } else {
                    this.rowStyles.splice(isFontSize, 1, fontSize)
                }
            } else {
                this.rowStyles.push(fontSize)
            }
        },

        setFontFormat(fontFormat) {
            let found = this.rowStyles.find(style => style === fontFormat)

            if (found) {
               this.removeStyle(fontFormat)
            } else {
               this.rowStyles.push(fontFormat)
            }
        },

        setBorderFormat(borderFormat) {
            if (this.rowStyles.join(' ').includes(borderFormat.class)) {
                let index = this.rowStyles.findIndex(r => r.includes(borderFormat.class))
                this.rowStyles.splice(index, 1)

            } else {
                if (!this.selectedBorderSize) {
                    this.selectedBorderSize = 1
                }

                this.rowStyles.push(`${borderFormat.class}-${this.selectedBorderSize}`)
            }
        },

        setBorderSize(borderSize) {
            this.selectedBorderSize = borderSize.size
            if (this.rowStyles.join(' ').includes('border')) {
                this.rowStyles.forEach(r => {
                    if (r.includes('border')) {
                        let index = this.rowStyles.findIndex(rs => rs === r)
                        let borderStyle = r.split('-')
                        borderStyle[2] = this.selectedBorderSize
                        this.rowStyles[index] = borderStyle.join('-')
                    }
                })
            }
        },

        setSelectedBorderFormat(borderFormat) {
            let selected = false
            this.rowStyles.find(r => {
                if (r.includes('border')) {
                    let format = r.split('-').slice(0, 2).join('-')

                    if (format === borderFormat.class) {
                        selected = true
                    }
                }
            })
            
            return selected
        }
    },
}
</script>

<style scoped>
.v-dialog__content {
    z-index: 2000 !important;
}

/* --- CUSTOM CSS STYLES --- */

/* FONTS */
.font-smaller {
  font-size: 10px !important;
}

.font-small {
  font-size: 12px !important;
}

.font-normal {
  font-size: 14px !important;
}

.font-big {
  font-size: 16px !important;
}

.font-bigger {
  font-size: 18px !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-light {
  font-weight: lighter !important;
}

.font-italic {
  font-style: italic;
}

.font-underline {
    text-decoration: underline;
}

/* COLORS */
.bordeaux-1 {
  background-color: #EFBFBF !important;
}

.bordeaux-2 {
  background-color: #DF7F7F !important;
}

.bordeaux-3 {
  background-color: #D04040 !important;
}

.bordeaux-4 {
  background-color: #C00000 !important;
}

.red-1 {
  background-color: #FFBFBF !important;
}

.red-2 {
  background-color: #FF7F7F !important;
}

.red-3 {
  background-color: #FF4040 !important;
}

.red-4 {
  background-color: #FF0000 !important;
}

.gold-1 {
  background-color: #FFEFBF !important;
}

.gold-2 {
  background-color: #FFDF7F !important;
}

.gold-3 {
  background-color: #FFD040 !important;
}

.gold-4 {
  background-color: #FFC000 !important;
}

.yellow-1 {
  background-color: #FFFFBF !important;
}

.yellow-2 {
  background-color: #FFFF7F !important;
}

.yellow-3 {
  background-color: #FFFF40 !important;
}

.yellow-4 {
  background-color: #FFFF00 !important;
}

.lime-1 {
  background-color: #E4F3D3 !important;
}

.lime-2 {
  background-color: #C8E7A7 !important;
}

.lime-3 {
  background-color: #ADDC7C !important;
}

.lime-4 {
  background-color: #92D050 !important;
}

.green-1 {
  background-color: #BFEBD3 !important;
}

.green-2 {
  background-color: #7FD7A7 !important;
}

.green-3 {
  background-color: #40C47C !important;
}

.green-4 {
  background-color: #00B050 !important;
}

.blue-1 {
  background-color: #BFEBFB !important;
}

.blue-2 {
  background-color: #7FD7F7 !important;
}

.blue-3 {
  background-color: #40C4F4 !important;
}

.blue-4 {
  background-color: #00B0F0 !important;
}

.royal-1 {
  background-color: #BFDBEF !important;
}

.royal-2 {
  background-color: #7FB7DF !important;
}

.royal-3 {
  background-color: #4094D0 !important;
}

.royal-4 {
  background-color: #0070C0 !important;
}

.navy-1 {
  background-color: #BFC7D7 !important;
}

.navy-2 {
  background-color: #7F8FAF !important;
}

.navy-3 {
  background-color: #405888 !important;
}

.navy-4 {
  background-color: #002060 !important;
}

.purple-1 {
  background-color: #DBCBE7 !important;
}

.purple-2 {
  background-color: #B797CF !important;
}

.purple-3 {
  background-color: #9464B8 !important;
}

.purple-4 {
  background-color: #7030A0 !important;
}

.silver-1 {
  background-color: #E8E8E8 !important;
}

.silver-2 {
  background-color: #D2D2D2 !important;
}

.silver-3 {
  background-color: #BBBBBB !important;
}

.silver-4 {
  background-color: #A5A5A5 !important;
}

.total-column {
  background-color: var(--primary-super-light-color) !important;
}

/* --- BORDERS --- */
.border-all-1 {
  box-sizing: border-box !important;
  border-bottom: 1px solid black !important;
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;
}

.border-all-2 {
  box-sizing: border-box !important;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important;
  border-top: 2px solid black !important;
}

.border-all-3 {
  box-sizing: border-box !important;
  border-bottom: 3px solid black !important;
  border-left: 3px solid black !important;
  border-top: 3px solid black !important;
}

.border-all-4 {
  box-sizing: border-box !important;
  border-bottom: 4px solid black !important;
  border-left: 4px solid black !important;
  border-top: 4px solid black !important;
}

.border-sides-1 {
  box-sizing: border-box !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.border-sides-2 {
  box-sizing: border-box !important;
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
}

.border-bottom-1 {
  box-sizing: border-box !important;
  border-bottom: 1px solid black !important;
}

.border-bottom-2 {
  box-sizing: border-box !important;
  border-bottom: 2px solid black !important;
}

.border-bottom-3 {
  box-sizing: border-box !important;
  border-bottom: 3px solid black !important;
}

.border-bottom-4 {
  box-sizing: border-box !important;
  border-bottom: 4px solid black !important;
}

.border-top-1 {
  box-sizing: border-box !important;
  border-top: 1px solid black !important;
}

.border-top-2 {
  box-sizing: border-box !important;
  border-top: 2px solid black !important;
}

.border-top-3 {
  box-sizing: border-box !important;
  border-top: 3px solid black !important;
}

.border-top-4 {
  box-sizing: border-box !important;
  border-top: 4px solid black !important;
}

/* READ ONLY COLUMN */
.read-only-column {
  background-color: #f0f0f0 !important;
}

.read-only-row {
  background-color: #f0f0f0 !important;
}
</style>
